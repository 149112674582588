var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchSuggestion" }, [
    _c("div", { staticClass: "searchSuggestionInner" }, [
      _c("div", {
        staticClass: "left",
        style: {
          "background-image":
            "url('" + require("@/assets/images/image_nearby.jpg") + "')",
        },
      }),
      _c("div", { staticClass: "right" }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.label) } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }