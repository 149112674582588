<template>
    <div class="creditInfo">
        <b-popover :target="'popover-credito-non-sufficiente'+_uid" triggers="hover" placement="bottom">
            
            <!-- caso jointly -->
            <span v-if="mixinGetIntegrationInfo().integration.integration == 'jointly'">
            Potrai pagare con carta di credito sul portale Jointly
            </span>
            <!-- caso sso + welfare == false -->
            <span v-else-if="mixinGetIntegrationInfo().sso && mixinGetIntegrationInfo().welfare == false">
            Potrai pagare la differenza con carta di credito
            </span>
            <!-- tutti gli altri casi -->
            <span v-else>
            Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam
            </span>
            
            <!--TODO gestire caso jointly-->
            <div class="contattaciPerUpselling"
            v-if="
                mixinGetIntegrationInfo().integration.integration !== 'jointly' &&
                mixinGetIntegrationInfo().upselling.enabled
            "
            >
                <p class="d-none d-sm-block mb-0"><SvgPhone /> Contattaci al: <span class="number">{{$config.site.phoneNumber}}</span></p>
                <a class="d-block d-sm-none btn btn-secondary btn-sm" :href="'phone:'+$config.site.phoneNumber"><SvgPhone /> Chiamaci!</a>
            </div>
        </b-popover>
        <span class="creditKoCopy" :id="'popover-credito-non-sufficiente'+_uid">
        <SvgInfoCircle /> credito non sufficiente
        </span>
    </div>
</template>


<script>
import SvgInfoCircle from './../../svg/info-circle'
import SvgPhone from './../../svg/phone'
export default {
    name: 'credit-tooltip',
    components:
    {
        SvgInfoCircle,
        SvgPhone,
    },
    props:
    {
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .creditInfo
    {
    }
</style>