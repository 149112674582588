var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { attrs: { id: "appFooter" } },
    [
      _c("Help"),
      _c("hr"),
      _c("ValuePropositions"),
      _c("hr"),
      this.config.newsletters && this.config.newsletters.consumer
        ? _c("Newsletter", {
            attrs: { newsletter: this.config.newsletters.consumer },
          })
        : _vm._e(),
      _c("hr"),
      _c("Links"),
      _c(
        "BackToTop",
        { attrs: { bottom: "50px", right: "50px", visibleoffset: "600" } },
        [
          _c(
            "b-button",
            { attrs: { size: "sm", variant: "primary" } },
            [_c("SvgChevronUp")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }