<template>
    <div class="searchSuggestion">
        <div class="searchSuggestionInner">
            <div class="left" v-bind:style="{ 'background-image': 'url(\'' + require('@/assets/images/image_nearby.jpg') + '\')' }"></div>
            <div class="right">
                <p v-html="label">
                </p>
                <!--<b-button @click="search()">Cerca</b-button>-->
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'search-suggestion',
    props: 
    {
        label: String,
    },
    methods:
    {
        search()
        {
            this.mixinSendMutation('setMatchLevel', 'nearby')
            this.mixinSendMutation('setTriggerSearch', true)
            this.$parent.$parent.$parent.$parent.getResults()
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .searchSuggestion
    {
        max-width: 800px;
        margin:0 auto;
        margin-top:$grid-gutter-width;
        margin-bottom:$grid-gutter-width;
        .searchSuggestionInner
        {
            height: 100%;
        }
        .left
        {
            min-height:200px;
            background-size:cover;
            background-position:center center;
        }
        .right
        {
            color:$black;
            background:$white;
            padding:1rem;
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction:column;
            text-align:center;
            strong
            {
                //white-space:nowrap;
            }
        }
        p
        {
            font-size: 1.15rem;
        }
        button
        {
            box-shadow:none;
            margin-top:1rem;
            display:block;
            width:100%;
            max-width: 200px;
        }
        @include media-breakpoint-up(lg)
        {
            display:flex;
            .left,.right
            {
                min-height:280px;
                flex:1;
                max-width:50%;
            }
        }
    }    
</style>