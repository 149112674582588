<template>
    <div class="searchBoxsetAlert">
        <div class="top">
            Desideri prenotare una di queste strutture?
        </div>
        <div class="bottom">
            <p>Acquista il voucher</p>
            <BoxsetResume
                :focus="focus"
                :band="band"
                :nights="nights"
                :bookingHidePrice="$config.guiSettings.bookingHidePrice"
            />
            <button class="btn btn-block btn-secondary" v-if="mixinGetIntegrationInfo().mode !== 'booking'" @click="boxsetSelected">Scegli</button>
            <TornaIndietro />
        </div>
    </div>    
</template>

<script>
import BoxsetResume from '@/components/blocks/boxset/boxset-resume'
import TornaIndietro from '@/components/blocks/common/torna-indietro'
import {mapGetters} from "vuex";

export default {
    name: 'search-boxset-alert',
    props: {

    },
    components:
    {
        BoxsetResume,
        TornaIndietro,
    },
    computed: mapGetters({
      focus: 'getFocus',
      band: 'getActiveBand',
      nights: 'getNights',
    }),
    methods:
    {
        boxsetSelected()
        {
            this.$parent.$emit('boxset-selected', this.$config.boxsetsConfig[this.focus][this.band][this.nights])
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .searchBoxsetAlert
    {
        display:flex;
        flex-direction: column;
        justify-content:space-between;
        min-height: 100%;
        .top
        {
            background:$primary;
            width:100%;
            color:$white;
            padding:1.5rem 1.25rem;
            text-align: center;
            font-weight:bold;
            font-size:18px;
        }
        .bottom
        {
            flex:1;
            padding:1rem 1.5rem;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p
            {
                font-size:14px;
            }
            .boxsetResume
            {
                justify-content:center;
                .boxsetName,.salaBadge
                {
                    font-size: 16px;
                }
            }            

            button
            {
                margin-top:2rem;
                margin-bottom:0;
            }

            .torna-indietro
            {
                margin-bottom:0.5rem !important;
            }
        }
    }
</style>