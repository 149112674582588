var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "noResults" }, [
    _c("div", { staticClass: "noResultsTop" }, [
      _c("div", { staticClass: "noResultsText" }, [
        _c("strong", [
          _vm.mode == "noVouchers"
            ? _c("span", [
                _vm._v(
                  "\n                    Non ci sono risultati per la tua ricerca\n                "
                ),
              ])
            : _c("span", [
                _vm.mixinGetIntegrationInfo().mode == "boxset"
                  ? _c("span", [
                      _vm._v(
                        "Non ci sono disponibilità per questa ricerca e questo voucher"
                      ),
                    ])
                  : _vm._e(),
                _vm.mixinGetIntegrationInfo().mode == "booking"
                  ? _c("span", [
                      _vm._v(
                        "Il tuo voucher non ha risultati per questa ricerca"
                      ),
                    ])
                  : _vm._e(),
              ]),
        ]),
        _vm.mode == "noVouchers"
          ? _c("div", { staticClass: "noVouchers" }, [
              _c("ul", [
                _c("li", [_vm._v("Dove: " + _vm._s(_vm.voucherInfo.focus))]),
                _c("li", [
                  _vm._v("Budget: " + _vm._s(_vm.voucherInfo.budget) + " €"),
                ]),
                _c("li", [
                  _vm.voucherInfo.nights !== 1
                    ? _c("span", [_vm._v("Notti")])
                    : _c("span", [_vm._v("Notte")]),
                  _vm._v(": " + _vm._s(_vm.voucherInfo.nights)),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.mode == "noDestinations"
          ? _c("div", [
              _c("div", [
                _c("br"),
                _c("p", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.label) +
                      " - " +
                      _vm._s(_vm._f("formatDate")(_vm.checkin)) +
                      ",\n                        " +
                      _vm._s(_vm.nights) +
                      " "
                  ),
                  _vm.nights !== 1
                    ? _c("span", [_vm._v("notti")])
                    : _c("span", [_vm._v("notte")]),
                  _vm._v(
                    ",\n                        " + _vm._s(_vm.adults) + " "
                  ),
                  _vm.adults !== 1
                    ? _c("span", [_vm._v("adulti")])
                    : _c("span", [_vm._v("adulto")]),
                  _vm.children > 0
                    ? _c("span", [
                        _vm._v(" - " + _vm._s(_vm.children) + " "),
                        _vm.children !== 1
                          ? _c("span", [_vm._v("bambini")])
                          : _c("span", [_vm._v("bambino")]),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "p",
                  [
                    _c("BoxsetResume", {
                      attrs: {
                        focus: _vm.focus,
                        band: _vm.band,
                        nights: _vm.nights,
                        bookingHidePrice:
                          _vm.$config.guiSettings.bookingHidePrice,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("br"),
    _c("div", { staticClass: "noResultsBottom" }, [
      _vm.mode == "noVouchers"
        ? _c("div", { staticClass: "noVouchers" }, [
            _vm.$config.bestSellersBoxsets
              ? _c("div", [_c("br"), _c("br"), _c("BestSellers")], 1)
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.mode == "noDestinations"
        ? _c(
            "div",
            [
              _c("NoResultsHelp"),
              _vm.mixinGetIntegrationInfo().integration == false
                ? _c("div", [
                    _c("br"),
                    _c(
                      "div",
                      { staticClass: "panel panel-gray" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "text-left d-flex align-items-center",
                                attrs: { sm: "12", lg: "4" },
                              },
                              [
                                _c("strong", [
                                  _vm._v("NON HAI UNA"),
                                  _c("br"),
                                  _vm._v("DESTINAZIONE PRECISA?"),
                                ]),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "text-center d-flex align-items-center flex-column",
                                attrs: { sm: "12", lg: "4" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "btn-block btn-transparent-secondary",
                                    attrs: { size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mixinGoTo(
                                          "voucher-perfetto",
                                          {}
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Scegli il voucher perfetto")]
                                ),
                                _c("div", { staticClass: "mt-2" }, [
                                  _c("small", [
                                    _c(
                                      "strong",
                                      [
                                        _c("CopyScadenza", {
                                          attrs: {
                                            default:
                                              "e prenditi {value} mesi per decidere dove andare",
                                            promoMonths:
                                              "e prenditi {value} mesi per decidere dove andare",
                                            promoFixed:
                                              "e hai tempo fino al {value} per decidere dove andare",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm.$config.guiSettings.showInSalabam
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-center d-flex align-items-center flex-column",
                                    attrs: { sm: "12", lg: "4" },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "btn-block btn-transparent-secondary",
                                        attrs: { size: "sm" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.mixinGoTo(
                                              "homepage-in-salabam",
                                              {}
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Lasciati ispirare")]
                                    ),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c("small", [
                                        _c(
                                          "strong",
                                          [
                                            _c("CopyScadenza", {
                                              attrs: {
                                                default:
                                                  "e prenditi {value} mesi per decidere dove andare",
                                                promoMonths:
                                                  "e prenditi {value} mesi per decidere dove andare",
                                                promoFixed:
                                                  "e hai tempo fino al {value} per decidere dove andare",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _c("div"),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }