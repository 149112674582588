<template>
    <div>
        <b-container fluid class="catalogResults">
            <b-row>
                <b-col sm="12">
                    <h1 class="common-title catalogTitle">{{title}}</h1>
                </b-col>


                <!-- con gli slice mostriamo alcuni risultati, poi pads informative, poi il resto -->
                <div class="boxsets">
                <b-row>
                    <b-col sm="12" md="4" lg="3" v-for="(boxset,index) in (boxsets.slice(0,6))" v-bind:key="'step-1'+index">
                        <BoxsetCatalog :boxset="boxset" />
                    </b-col>

                    <b-col sm="12" lg="6">
                        <b-container>
                        <ComeFunzionaSalabam />
                        </b-container>
                    </b-col>
                    
                    <b-col sm="12" md="4" lg="3" v-for="(boxset,index) in (boxsets.slice(6,boxsets.length))" v-bind:key="'step-2'+index">
                        <BoxsetCatalog :boxset="boxset" />
                    </b-col>

                    <b-col sm="12">
                        <b-container class="mb-4 pb-4">
                        <br><br>
                        <TermsShort />
                        <br><br>
                        </b-container>
                    </b-col>

                </b-row>
                </div>
            </b-row>
        </b-container>
        
        <b-container fluid class="catalogResults" v-if="boxsets.length == 0">
            <NoResults :voucherInfo="{focus:focus,nights:nights,budget:budget}" v-on:submit-search="$emit('get-catalogo')" />
        </b-container>
    </div>
</template>


<script>
  import NoResults from '../blocks/no-results'
  import BoxsetCatalog from '../blocks/boxset/boxset-catalog'
  import ComeFunzionaSalabam from './../blocks/common/come-funziona-salabam'
  import TermsShort from './../blocks/terms-short'

  export default {
    name: 'catalog',
    metaInfo: {
      title: 'catalog',
    },
    components: {
      BoxsetCatalog,
      NoResults,
      ComeFunzionaSalabam,
      TermsShort
    },
    props:{
        boxsets: Array,
        focus: String,
        nights: Number,
        budget: String,
        title: String,
    },
    data() {
      return {
      }
    },
    computed:
    {
    },
    mounted() {
    }
  }
</script>
