var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M21.1999 15.855L18.1789 12.834C17.8957 12.5527 17.5127 12.3948 17.1135 12.3948C16.7143 12.3948 16.3312 12.5527 16.048 12.834L14.675 14.207C11.5218 12.7128 8.98276 10.1735 7.489 7.02002L8.86095 5.64697C9.1435 5.36436 9.30223 4.98117 9.30223 4.58154C9.30223 4.18192 9.1435 3.79872 8.86095 3.51611L5.84203 0.496094C5.55912 0.213727 5.1757 0.0549316 4.77599 0.0549316C4.37628 0.0549316 3.99285 0.213727 3.70995 0.496094L1.52794 2.67603L1.43395 2.77588C-0.327055 4.68788 0.555967 9.23799 6.50597 15.188C9.77897 18.461 13.7389 21.105 16.6059 21.105C17.4606 21.1357 18.2935 20.8315 18.927 20.2571L21.2049 17.9839C21.4863 17.7006 21.6441 17.3177 21.6441 16.9185C21.6441 16.5192 21.4863 16.1363 21.2049 15.853",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }