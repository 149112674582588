<template>
  <div id="search" v-bind:key="refreshKey" v-if="Object.keys(config).length !== 0">
    <div id="main" :class="Object.keys(boxsets).length > 0 ? 'hasResults' : 'noResults'">

      <Sidebar
        :loading="!!(loading|appending)"
        :boxsets="boxsets"
        :bands="bands"
        :resultsFiltered="resultsFiltered"
        :focus="focus"
        :hasResults="Object.keys(boxsets).length !== 0"
        :sidebarNights="sidebarNights"
        v-on:nightly-price-changed="nightlyPriceChanged"
        v-on:boxset-selected="boxsetSelected"
        v-on:submit-search="submitSearch"
      />

      <div id="content" :class="'boxset-' + activeBand">
        <SearchBar
          :loading="!!(loading|appending)"
          :band="activeBand"
          :focus="focus"
          :geo="geo"
          :dates="dates"
          :occupancy="occupancy"
          :hasResults="Object.keys(boxsets).length !== 0"
          :offset="offset"
          :lastSearch="lastSearch"
          v-on:submit-search="submitSearch"
          v-on:filter-changed="filterChanged"
        />

        <!-- search results -->
        <div id="page" :class="(loading||appending) ? 'pageLoading' : ''">
          <Spinner v-if="loading" class="text-center" />      

          <div v-else-if="
              this.$route.name == 'property'
            | this.$route.name == 'map-detail'
            | this.$route.name == 'map-list'
            | this.$route.name == 'before-checkout'
          ">
            <router-view></router-view>
          </div>

          <p v-else-if="Object.keys(boxsets).length == 0" class="searchEmpty">
            Inizia a cercare!
          </p>

          <div v-else> 
            
            <b-card no-body class="noheader">
              <b-tabs card no-fade v-model="activeBandIndex" :key="resultsFiltered[this.activeBand].length">
                <div v-for="(value,name,index) in bands" v-bind:resultsFiltered="resultsFiltered" v-bind:key="'propertys-'+index">

                  <b-tab v-if="resultsFiltered[value].length > 0">
                    <b-card-text>
                      <Results
                        :dates="dates"
                        :geoLabel="geo.label"
                        :total="resultsFiltered[value].length"
                        :defaultResultsLength="(boxsets[value] !== undefined && boxsets[value][dates.nights] !== undefined) ? boxsets[value][dates.nights].defaultResultsLength : 999999"
                        :activeBand="activeBand"
                        :focus="focus"
                        :properties="resultsFiltered[value]"
                        :canLoadMore="offset!==-1 && !loading && !appending"
                        v-on:reset-results="resetResultsAndTrigger"
                        v-on:submit-search="submitSearch"
                        v-on:filter-changed="filterChanged"
                        v-on:boxset-selected="boxsetSelected"
                        v-on:load-more="getResults"
                      />
                    </b-card-text>

                    <div class="cta__load-more text-center" v-if="offset!==-1 && resultsFiltered[activeBand].length > 0">
                      <b-button variant="secondary" v-if="!appending" @click="getResults">
                        Aggiungi altre proposte
                      </b-button>
                      <div v-else><b-spinner variant="primary" /></div>
                    </div>
                  </b-tab>

                  <b-tab v-else title-item-class="">
                    <NoResults
                      :destinationInfo="{label:geo.label,focus:focus,band:activeBand,dates:dates,occupancy:occupancy}"
                      v-on:submit-search="getResults"
                    />
                  </b-tab><!-- this is important to match zero-based boxsets index! -->
                </div>
              </b-tabs>
            </b-card>
          </div>

        </div><!--#page-->

        <b-container fluid>
          <b-row v-if="$route.name == 'search'">
            <b-col sm="12">
              <div class="text-center">
                <br>
                <BtnBack :text="'Torna indietro'" />
              </div>
            </b-col>
          </b-row>
        </b-container>

        <PageFooter />
        
      </div><!--#content-->
      
    </div><!--#main-->
  </div><!--#search-->
</template>

<script>
  import Vue from 'vue'
  import Store from '@/vuex/store.js'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import Api from '../../api'
  import Sidebar from '../blocks/sidebar/sidebar'
  //import MobileBoxsetFilter from '../blocks/search-form/mobile-boxset-filter'
  import SearchBar from '../blocks/search-bar'
  import Spinner from '../atoms/spinner'
  import BtnBack from '../atoms/btn-back'
  import Results from '../views/search-results'
  import NoResults from '../blocks/no-results'
  import PageFooter from '@/components/blocks/footer/page-footer'

  export default {
    name: 'search',
    metaInfo(){
      return {
        title: 'cerca la tua destinazione',
        meta: [
          { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: '404' }
        ]
      }
    },
    components: {
      SearchBar,
      Sidebar,
      //MobileBoxsetFilter,
      Spinner,
      BtnBack,
      Results,
      NoResults,
      PageFooter,
      /*PageFooter: () => import('@/components/blocks/footer/page-footer').then(function(PageFooter) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async: PageFooter Component loaded')
        return PageFooter
      }),*/
    },
    props:{
    },
    computed: {
      occupancy: {
        get() { return this.$store.state.occupancy },
        set(value) { this.mixinSendMutation('setOccupancy',value) }
      },
      triggerSearch: {
        get() { return this.$store.state.triggerSearch },
        set(value) { this.mixinSendMutation('setTriggerSearch',value) }
      },
      dates: {
        get() { return this.$store.state.dates },
        set(value) { this.mixinSendMutation('setDates',value) }
      },
      geo: {
        get() { return this.$store.state.geo },
        set(value) { this.mixinSendMutation('setGeo',value) }
      },
      selectedAmenities: {
        get() { return this.$store.state.amenities },
        set(value) { this.mixinSendMutation('setAmenities',value) }
      },
      selectedCategories: {
        get() { return this.$store.state.categories },
        set(value) { this.mixinSendMutation('setCategories',value) }
      },
      activeBand: {
        get() { return this.$store.state.activeBand },
        set(value) {
          this.mixinSendMutation('setActiveBand',value)
        }
      },
      activeBandIndex: {
        get() {
          for (let key in this.bands) {
              if(this.bands[key] == this.activeBand)
              {
                return (Object.keys(this.bands)).indexOf(key)
              }
          }
          return 0
        },
        set(){
          //see sidebar.vue for the setter
        }
      },
      token: {
        get() { return this.$store.state.token },
        set(value) { this.mixinSendMutation('setToken',value) }
      },
      searchCounter: {
        get() { return this.$store.state.searchCounter },
        set(value) { this.mixinSendMutation('setSearchCounter',value) }
      },
      offsetCounter: {
        get() { return this.$store.state.offsetCounter },
        set(value) { this.mixinSendMutation('setOffsetCounter',value) }
      },
      searchLogId: {
        get() { return this.$store.state.searchLogId },
        set(value) { this.mixinSendMutation('setSearchLogId',value) }
      },
      map: {
        get() { return this.$store.state.map },
        set(value) { this.mixinSendMutation('setMap',value) }
      },
      unlockUpselling: {
        get() { return this.mixinGetIntegrationInfo().mode == 'booking' && this.$store.state.unlockUpselling },
        set(value) { this.mixinSendMutation('setUnlockUpselling',value) }
      },
      nightlyPrice: {
        get() { return this.$store.state.nightlyPrice },
        set(value) { this.mixinSendMutation('setNightlyPrice',value) }
      }
    },
    data() {
      return {
        refreshKey: '',
        config: {},
        bands:{},
        boxsets: {},
        loading: false,
        appending: false,
        results: {},
        offset: 0,
        resultsFiltered: {},
        lastSearch: '',
        focus: '',
        sidebarNights: this.$store.state.dates.nights,
      }
    },
    beforeCreate(){
    },
    created(){
      this.config = Vue.prototype.$config
      if(this.triggerSearch) {
        this.getResults()
      }
    },
    beforeMount(){
    },
    mounted() {
      Vue.use(VueGoogleMaps, {
        load: {
          key: Vue.prototype.$config.googleMapsApikey,
          //libraries: 'places', // This is required if you use the Autocomplete (google autocomplete ^_^) plugin
          // OR: libraries: 'places,drawing'
          // OR: libraries: 'places,drawing,visualization'
          // (as you require)
          //// If you want to set the version, you can do so:
          // v: '3.26',
        },
        options:
        {
          strokeColor: '#6610f2'
        },
      
        //// If you intend to programmatically custom event listener code
        //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
        //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
        //// you might need to turn this on.
        // autobindAllEvents: false,
      
        //// If you want to manually install components, e.g.
        //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
        //// Vue.component('GmapMarker', GmapMarker)
        //// then set installComponents to 'false'.
        //// If you want to automatically install all the components this property must be set to 'true':
        installComponents: true
      })    
    },
    methods: {
      nightlyPriceChanged(value)
      {
        // i controlli su upselling.enabled e Store.state.unlockUpselling vengono fatti in sidebar.vue prima di lanciare l'evento
        this.nightlyPrice = value
      },

      boxsetSelected(boxset)
      {
        let _this = this
        _this.mixinBoxsetSelected(boxset)
        setTimeout(function(){

            if(_this.mixinGetIntegrationInfo(0).mode == 'booking')
            {
              if(process.env.VUE_APP_MODE != 'production') window.console.log('questo tasto in booking non sarebbe stato da visualizzare (in booking dovresti conoscere la camera per andare al checkout)')
            }

            if(_this.mixinGetIntegrationInfo().mode == 'boxset')
            {
                if(_this.mixinGetIntegrationInfo().sso == true)
                {
                    _this.$router.push({ name: 'checkout' })
                    return
                }
                _this.$router.push({ name: 'before-checkout' })
            }
        },100)
      },
      
      submitSearch: function(data)
      {
        if(data == undefined)
        {
          this.mixinGoTo('search',{})
          this.getResults()
          return
        }
        
        if(data.navigate)
        {
          this.mixinGoTo('search',{})
          this.getResults()
        }
      },
      
      getResults: function()
      {
        let _this = this,
            nightlyPrice = 0

        _this.triggerSearch = true

        if(_this.mixinGetIntegrationInfo().upselling.enabled && _this.unlockUpselling)
        {
          nightlyPrice = _this.nightlyPrice
        }

        //let numberOfCalls = Math.max(_this.offsetCounter, _this.config.searchSettings.minPreviewCalls)
        //for (let index = 0; index < numberOfCalls; index++) {


          // se dovesse essere triggerata una ricerca senza data, forzo 15gg in avanti da oggi
          if(_this.dates.checkin == ''){
            let ci = new Date()
            ci.setDate(ci.getDate() + 15)
            _this.dates.checkin = ci.toISOString().slice(0,10)
          }

          // i campi di match devono corrispondere a search-form.vue disableSearchButton() let match
          let match = {
              checkin: _this.dates.checkin,
              nights: _this.dates.nights,
              occupancy: _this.occupancy,
              lat: _this.geo.latitude,
              lng: _this.geo.longitude,
              matchLevel: _this.geo.matchLevel,
              inSalabamId: (_this.geo.inSalabamId)? _this.geo.inSalabamId : 0,
              property_id: (_this.geo.property_id)? _this.geo.property_id : 0,
              nightlyPrice: nightlyPrice,
              properties_ids: (_this.geo.properties_ids)? _this.geo.properties_ids : [],
            }

          let newSearch = window.btoa(JSON.stringify(Object.keys(match).sort().reduce(
              (obj, key) => {
                obj[key] = match[key];
                return obj;
              },
              {}
          )))

          if (newSearch !== _this.lastSearch) {
            _this.offset = 0
            _this.loading = true
            _this.searchCounter = _this.searchCounter + 1
          }
          else
          {
            _this.appending = true
            _this.offsetCounter = _this.offsetCounter + 1
          }

          let parameters = {
              checkin: _this.dates.checkin,
              nights: _this.dates.nights,
              occupancy: _this.occupancy,
              lat: _this.geo.latitude,
              lng: _this.geo.longitude,
              offset: _this.offset,
              matchLevel: _this.geo.matchLevel,
              inSalabamId: (_this.geo.inSalabamId)? _this.geo.inSalabamId : 0,
              property_id: (_this.geo.property_id)? _this.geo.property_id : 0,
              properties_ids: (_this.geo.properties_ids)? _this.geo.properties_ids : [],

              label: _this.geo.label,
              geoLabel: _this.geo.label, // l'api si aspetta geoLabel, forse "label" è un refuso ma lo lascio a scanso di equivoci

              searchCounter: _this.searchCounter,
              debugString: _this.debugString,
              token: Store.state.token,
              nightlyPrice: nightlyPrice,

              focus: _this.$store.state.focus,
            }

          _this.lastSearch = newSearch

          _this.getPreview(parameters, 'default')//'default', 'nearby

      },

      getPreview(parameters, mode)
      {
        let _this = this,
            needsNearBySearch = false, //flag che attiva la chiamata nearby in background se ci sono pochi risultati in almeno un cofanetto
            searchSuggestionMinResults = _this.config.searchSettings.searchSuggestionMinResults

        /* loader gestiti in getResults, in cui si discrimina anche il caso loading vs appending
        _this.loading = false
        _this.appending = false
        */

        Api
          .getPreview(parameters)
          .then((preview) => {

            // usato per upselling, l'api ritorna info su che cofanetti iniettare nella conf
            if(preview.updateConfig)
            {
              _this.mixinUpdateConfig(preview.updateConfig)
            }

            _this.sidebarNights = preview.nights // tiene allineate le notti della sidebar con quanto di ritorno dall'api

            let resultsFilteredTemp = _this.getResultsFiltered(preview.results)

            // PRE loop risultati + loop risultati

            if(mode == 'default')
            {

              // alcuni matchLevel non devono triggerare nearby
              let matchLevelsNoNeedsNearBySearch = ['inSalabam', 'property', 'properties', 'favorites', 'history'];

              if(_this.offset == 0)
              {
                _this.results = preview.results
                _this.resultsFiltered = _this.getResultsFiltered(_this.results)
                _this.bands = _this.config.bands[preview.focus]
                _this.boxsets = _this.config.boxsetsConfig[preview.focus]

                if(preview.geography)
                {
                  // se inSalabam non ritorna geography
                  // preview.geography può essere rimandata alle api
                  // essendoci coordinate decimali, nel rimbalzo qualcosa le incasina
                  // scambiandomele codificate inn b64 risolvo il problema
                  if(typeof(preview.geography) == 'string')
                  {

                  let tempGeography = JSON.parse(atob(preview.geography))
                  if(tempGeography) _this.map.geography = tempGeography

                  }
                  else
                  {
                    _this.map.geography = preview.geography // retrocompatibilità, aggiorno prima clients poi api
                  }

                } // if preview.geography

              } // if _this.offset == 0

              for (let boxset in preview.results)
              {
                if(_this.offset == 0)
                {
                  if(_this.results[boxset].length == 0) needsNearBySearch = (preview.matchLevel && preview.matchLevel != 'inSalabam' && preview.matchLevel != 'property') // vedi poco più sotto "inSalabam non hanno un poligono, non fare nearby"
                  
                  if(_this.results[boxset].length > 0) //ignoro eventuali cofanetti con 0 risultati che tanto non vengono mostrati in sidebar
                  {

                    needsNearBySearch = ( _this.results[boxset].length < searchSuggestionMinResults
                                         && preview.matchLevel
                                         && matchLevelsNoNeedsNearBySearch.indexOf(preview.matchLevel) === -1)

                    // tengo traccia della posizione in cui terminano i risultati che ho ottenuto con la chiamata di default per posizionare la pad
                    if(_this.boxsets[boxset] !== undefined)
                    {
                      _this.boxsets[boxset][parameters.nights].defaultResultsLength = -1
                      if(_this.results[boxset].length > 0)
                      {

                          // valorizzo solamente se serve nearBy altrimenti mostrerà la pad nearBy anche quando si clicca "carica altri risultati"
                          _this.boxsets[boxset][parameters.nights].defaultResultsLength = (needsNearBySearch)? _this.results[boxset].length : -2 // -1 già usato come flag
                      }
                    }
                  }
                }
                else
                {
                  _this.results[boxset] = _this.results[boxset].concat(preview.results[boxset])
                  _this.resultsFiltered[boxset] = _this.resultsFiltered[boxset].concat(resultsFilteredTemp[boxset])
                }
              }

            } // if mode default

            if(mode == 'nearby')
            {
              for (let boxset in preview.results)
              {
                if(_this.offset == 0)
                {
                  if(_this.results[boxset].length < searchSuggestionMinResults)
                  {
                    _this.results[boxset] = _this.results[boxset].concat(preview.results[boxset])
                    _this.resultsFiltered[boxset] = _this.resultsFiltered[boxset].concat(resultsFilteredTemp[boxset])
                  }
                }
                else
                {
                  if(_this.boxsets[boxset] !== undefined && _this.boxsets[boxset][parameters.nights] !== undefined)
                  {
                    _this.boxsets[boxset][parameters.nights].defaultResultsLength = -1
                    if(_this.results[boxset].length > 0)
                    {
                    _this.boxsets[boxset][parameters.nights].defaultResultsLength = _this.results[boxset].length
                    }
                  }     

                  // se serve fare un flag per appendere/non appendere gestire questa condizione
                  //if(_this.results[boxset].length < searchSuggestionMinResults)
                  //{
                      _this.results[boxset] = _this.results[boxset].concat(preview.results[boxset])
                      _this.resultsFiltered[boxset] = _this.resultsFiltered[boxset].concat(resultsFilteredTemp[boxset])
                  //}
                }
              }
            }

            // POST loop risultati

            _this.map.data = _this.resultsFiltered
            _this.searchLogId = preview.searchLogId

            // non serve aggiornare offset, focus e banda nel caso 'nearby', è già stato fatto alla chiamata 'default'
            if(mode == 'default')
            {
              _this.offset = preview.offset
              _this.focus = preview.focus
              //_this.lastSearch = newSearch // questo era replicato, viene gestito a monte in getResults

              _this.setActiveBand()
              _this.mixinSendMutation('setFocus', preview.focus) 

              // qui finiva la ricerca normale
              // ora decido se fare o meno la chiamata nearby in background
              // window.console.log('needsNearBySearch '+needsNearBySearch)
              if(needsNearBySearch)
              {

                parameters.matchLevel = 'nearby'

                // per ricerca nearby rimbalzo la geography usata nella prima ricerca
                // così che poi possa usare un poligono più grande e per "differenza" non includere
                // poligoni/risultati già selezionati
                if(preview.geography) parameters.geography = preview.geography

                _this.getPreview(parameters, 'nearby')

              }
              else
              {
                // a prescindere dal caso loading vs appending, se non serve una chiamata nearby allora il percorso di ricerca è finito e vanno messi a false entrambi
                _this.loading = false
                _this.appending = false
              }
            }
            
            // a prescindere dal caso loading vs appending, in nessun caso al termine della chiamata 'nearby' i flag loading,appending possono restare true
            if(mode == 'nearby')
            {
              _this.loading = false
              _this.appending = false
            }

          }) // Api.getPreview.then

      }, // getPreview

      setActiveBand()
      {
        // setto la banda attiva SOLO SE non c'è ancora una banda attiva (questo anche per la modalità nearby: se ho avuto ad esempio risultati pari a zero in chiamata default e risultati diversi da zero in chiamata nearby)
        if(
          (this.activeBand !== undefined) &&
          (this.activeBand !== '') &&
          (this.resultsFiltered[this.activeBand] !== undefined) &&
          (this.resultsFiltered[this.activeBand].length !== 0)
        )
        {
          return
        }

        // cerco la prima banda con risultati per settarla attiva
        let bandFound = false

        Object.keys(this.resultsFiltered).forEach(band => {
          if ((this.activeBand !== band) && (this.resultsFiltered[band].length > 0) && (bandFound == false))
          {
            bandFound = true
            this.mixinSendMutation('setActiveBand', band)
          }
        })

        if(!bandFound){
          // se la ricerca non ha nessun risultato metto in evidenza banda argento (che hanno tutti i focus)
          // o quella del cofanetto con cui ho fatto login

          let forceBand = 'argento'
          if(this.config.integration && this.config.integration.salabam && this.config.integration.salabam.band) forceBand = this.config.integration.salabam.band

          this.mixinSendMutation('setActiveBand', forceBand)
        }

      },

      getResultsFiltered(results){
        let resultsFiltered = {}
        for(let boxset in results)
        {
          let rooms = []
          for(let room in results[boxset])
          {
            if( this.selectedCategories.length == 0 | this.selectedCategories.includes(results[boxset][room].salabamCategory) )
            {
              let roomOk = true
              for (let f = 0; f < this.selectedAmenities.length; f++) {
                if (!(this.selectedAmenities[f] in results[boxset][room].amenities))
                {
                  roomOk = false
                  break
                }
              }
              if(roomOk) rooms.push(results[boxset][room])
            }
          }
          resultsFiltered[boxset] = rooms
        }
        return resultsFiltered
      },

      filterChanged()
      {
        this.mixinGoTo('search',{})
        if(this.results == undefined) {return}
        if(this.results.length == 0) {return}
        //if(this.results)
        {
          let _this = this
          _this.loading = true
          _this.mixinSendMutation('setScroll', 0)
          setTimeout(function(){
            _this.resultsFiltered = _this.getResultsFiltered(_this.results)
            _this.map.data = _this.resultsFiltered
            _this.loading = false
          },50)
        }
      },

      searchFormIsValid()
      {
        if(this.firstSearch & !this.triggerSearch)
        {
          this.firstSearch = false
          this.getResults()
        }
      },

      resetResultsAndTrigger()
      {
        let _this = this
        _this.loading = true
        _this.offset = 0
        setTimeout(function(){
          _this.results = {}
          _this.resultsFiltered = {}
          _this.offset = 0
          _this.getResults()
        },50)
      },

      resetResults()
      {
        let _this = this
        _this.loading = true
        _this.offset = 0
        setTimeout(function(){
          _this.results = {}
          _this.resultsFiltered = {}
          _this.loading = false
        },50)
      }
    },
    watch: {
      activeBand: function() {
        let _this = this
        _this.loading = true
        setTimeout(function(){
          _this.loading = false
        },500)
      }
    }
  }
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/mixins";
  @import './../../scss/_custom.scss';
  #search
  {

    .bookingEngineSecondRow.desktop
    {
      display:none !important;
    }

    .card-header
    {
      display:none !important;
    }
    .card
    {
      border:0;
      border-radius:0;
      background:transparent;
    }
    .card-body
    {
      padding:0;
    }
    
    .map-link
    {
      cursor:pointer;
      
      svg {
        width:100%;
        height:auto;
      }

      img {
        display:block;
        width: 100%;
        border-radius: 15px 15px 0 0;
        padding-bottom: 7px;;
      }

      span
      {
        color: $secondary;
        font-weight:bold;
        font-size:14px;
        white-space:nowrap;
        text-align:center;
        display: block;
        padding:4px 6px;
        background-color: #fff;
        position: relative;
        top: -8px;
        box-shadow: 3px 3px 6px rgb(0 0 0 / 15%);
      }
    }

    #main.noResults #page
    {
      margin-top:0;
    }

    .searchEmpty
    {
      margin:0 auto;
      text-align:center;
      display:flex;
      justify-content:center;
      align-items: center;
      min-height: 200px;
    }

    #sidebar
    {
      //display:none;
    }

    @include media-breakpoint-up(lg)
    {
      #mobileBoxsetFilter
      {
        display:none;
      }
      #sidebar
      {
        display:block;
      }
    }

    .noResults
    {
      .results-info p:last-child
      {
        margin-bottom:0;
      }
    }

    @include media-breakpoint-down(xs)
    {
      #page
      {
        //margin-top:0;
      }
      .card-text
      {
        margin-bottom:0;
      }
      .cta__load-more
      {
        margin-top:0;
        margin-bottom:1rem;
      }
    }
  }

  @include media-breakpoint-up(lg)
  {
    #app.search #appHeader
    {
      display:none;
    }
  }
</style>