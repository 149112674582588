<template>
  <footer id="appFooter">
    
    <Help />
    <hr />
    <ValuePropositions />
    <hr />
    <Newsletter v-if="this.config.newsletters && this.config.newsletters.consumer"
      :newsletter="this.config.newsletters.consumer"
    />
    <hr />
    <Links />
  
    <BackToTop bottom="50px" right="50px" visibleoffset="600">
      <b-button size="sm" variant="primary">
        <SvgChevronUp />
      </b-button>
    </BackToTop>

  </footer>
</template>

<script>

import Vue from "vue"
import SvgChevronUp from '../../svg/chevron-up'
import BackToTop from 'vue-backtotop' 

export default {
  name: 'page-footer',
  components:
  {
    BackToTop, SvgChevronUp,
    Help: () => import('@/components/blocks/footer/help').then(function(Help) {
      if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Help Component loaded')
      return Help
    }),
    ValuePropositions: () => import('@/components/blocks/footer/value-propositions').then(function(ValuePropositions) {
      if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ValuePropositions Component loaded')
      return ValuePropositions
    }),
    Newsletter: () => import('@/components/blocks/footer/newsletter').then(function(Newsletter) {
      if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Newsletter Component loaded')
      return Newsletter
    }),
    Links: () => import('@/components/blocks/footer/links').then(function(Links) {
      if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Links Component loaded')
      return Links
    }),
  },
  props: {
  },
  data (){
    return {
      config: Vue.prototype.$config,
    }
  },
}
</script>


<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/mixins";
  @import './../../../scss/_custom.scss';

  main + footer
  {
    margin-top:2rem;
    @include media-breakpoint-up(lg)
    {
      margin-top:4rem;
    }
  }

  footer
  {
    max-width: 100%;
    overflow:hidden;
    padding-left:$grid-gutter-width/2;
    padding-right:$grid-gutter-width/2;
    margin:4rem 0;

    hr
    {
      margin:2rem 0;
    }
    padding-bottom:4rem;

    @include media-breakpoint-down(sm)
    {
      .container-fluid
      {
        padding:0;
        .col-sm-12:not(:last-child)
        {
          margin-bottom: 1rem;
        }
      }
    }
    
    @include media-breakpoint-up(lg)
    {
      margin-left:$grid-gutter-width/2;
      margin-right:$grid-gutter-width/2;
      //padding-left:$grid-gutter-width;
      //padding-right:$grid-gutter-width;
    }    
  }


  .btn-to-top {
    width: 60px;
    height: 60px;
    padding: 10px 16px;
    border-radius: 50%;
    font-size: 22px;
    line-height: 22px;
  }

  .vue-back-to-top
  {
    z-index:1 !important;
    button
    {
      height:50px;
      width:50px;
      border-radius: 50%;
      display:flex;
      align-items: center;
    }
    svg path
    {
      stroke:$white;
    }
  }

</style>