var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "creditInfo" },
    [
      _c(
        "b-popover",
        {
          attrs: {
            target: "popover-credito-non-sufficiente" + _vm._uid,
            triggers: "hover",
            placement: "bottom",
          },
        },
        [
          _vm.mixinGetIntegrationInfo().integration.integration == "jointly"
            ? _c("span", [
                _vm._v(
                  "\n        Potrai pagare con carta di credito sul portale Jointly\n        "
                ),
              ])
            : _vm.mixinGetIntegrationInfo().sso &&
              _vm.mixinGetIntegrationInfo().welfare == false
            ? _c("span", [
                _vm._v(
                  "\n        Potrai pagare la differenza con carta di credito\n        "
                ),
              ])
            : _c("span", [
                _vm._v(
                  "\n        Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam\n        "
                ),
              ]),
          _vm.mixinGetIntegrationInfo().integration.integration !== "jointly" &&
          _vm.mixinGetIntegrationInfo().upselling.enabled
            ? _c("div", { staticClass: "contattaciPerUpselling" }, [
                _c(
                  "p",
                  { staticClass: "d-none d-sm-block mb-0" },
                  [
                    _c("SvgPhone"),
                    _vm._v(" Contattaci al: "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(_vm._s(_vm.$config.site.phoneNumber)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "a",
                  {
                    staticClass: "d-block d-sm-none btn btn-secondary btn-sm",
                    attrs: { href: "phone:" + _vm.$config.site.phoneNumber },
                  },
                  [_c("SvgPhone"), _vm._v(" Chiamaci!")],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "creditKoCopy",
          attrs: { id: "popover-credito-non-sufficiente" + _vm._uid },
        },
        [_c("SvgInfoCircle"), _vm._v(" credito non sufficiente\n    ")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }