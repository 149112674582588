<template>
  <div id="shop">
    <Spinner v-if="loading" />
    <div v-else>
      <div class="searchBoxsetWrapper">
        <b-container fluid>
          <b-row>
            <b-col sm="12">
              <SearchBoxset
                :triggerSearch="triggerSearch"
                :focus="focus"
                :budget="budget"
                :nights="nights"
                :showWelfare="showWelfare"
                v-on:submit-boxset="mixinGoTo('search-voucher',{})"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>

      <ShopResults
        v-if="boxsets.length > 0"
        v-on:get-catalogo="getCatalogo"
        :boxsets="boxsets"
        :focus="focus"
        :nights="nights"
        :budget="budget"
        :title="'Tutti i voucher Salabam disponibili'"
      />

    </div>
  </div>
</template>

<script>
  import Api from '../../api'
  //import { Base64 } from 'js-base64'
  import Spinner from '../atoms/spinner'
  import SearchBoxset from './../blocks/search-form/search-boxset'
  import ShopResults from '../blocks/shop-results'

  export default {
    name: 'catalog',
    metaInfo: {
      title: 'Acquista un voucher con il tuo credito welfare',
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Scopri tutta la gamma dei nostri voucher per ogni budget e per ogni voglia di viaggiare' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Scopri tutta la gamma dei nostri voucher per ogni budget e per ogni voglia di viaggiare' },
      ],
    },
    components: {
      Spinner,
      SearchBoxset,
      ShopResults,
    },
    props:{
      searchParameters: {
        type: Object,
        default: () => {}
      },

      urlFocus: {
        type: String,
        default: '',
      },

    },
    data() {
      return {
        loading: true,
        boxsets: [],
        triggerSearch: true,
      }
    },
    computed:
    {
      focus: {
        get() { return this.$store.state.focus },
        set(value) { this.mixinSendMutation('setFocus',value) }
      },
      budget: {
        get() { return this.$store.state.budget },
        set(value) { this.mixinSendMutation('setBudget',value) }
      },
      nights: {
        get() { return this.$store.state.dates.nights },
        set(value) { this.mixinSendMutation('setNights',value) }
      },
      showWelfare: {
        get() { return (this.mixinGetIntegrationInfo().sso ? this.$store.state.showWelfare : false) },
        set(value) { this.mixinSendMutation('setShowWelfare',value) }
      },
      boxsetCount:{
        get() { return this.boxsets.length},
        set() {},
      }
    },
    mounted() {

      /*
      let parameters = {}

      if(this.searchParameters !== undefined)
      {
        parameters = this.searchParameters

        this.focus = parameters.focus
        this.budget = parameters.budget
        this.nights = parameters.nights
        this.showWelfare = parameters.showWelfare

      }
      else if(this.$route.query.vouchers !== undefined)
      {
        //stringa di prova: eyJmb2N1cyI6Iml0YWxpYSIsImJ1ZGdldCI6IjMwMS00MDAiLCJuaWdodHMiOjIsInNob3dXZWxmYXJlIjpmYWxzZX0
        let v = this.$route.query.vouchers,
            vDec = Base64.decode(v)

        parameters = JSON.parse(vDec)

        this.focus = parameters.focus
        this.budget = parameters.budget
        this.nights = parameters.nights
        this.showWelfare = parameters.showWelfare

      }
      else
      {
        this.loading = false
        return
      }
      */

      this.focus = (this.urlFocus != '')? this.urlFocus : '-'
      this.budget = '0-0'

      this.getCatalogo()

    },
    methods: {
      getCatalogo()
      {      
        let _this = this
        
        _this.loading = true
        _this.triggerSearch = false

        let catalogParameters = {
          focus: _this.focus,
          pricerange: _this.budget,
        }

        Api.getCatalogo(catalogParameters)
        .then((results) => {
          _this.boxsets = results.boxsets
          _this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss">
//vedi _shop.scss
</style>