var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "shop" } },
    [
      _vm.loading
        ? _c("Spinner")
        : _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "searchBoxsetWrapper" },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12" } },
                            [
                              _c("SearchBoxset", {
                                attrs: {
                                  triggerSearch: _vm.triggerSearch,
                                  focus: _vm.focus,
                                  budget: _vm.budget,
                                  nights: _vm.nights,
                                  showWelfare: _vm.showWelfare,
                                },
                                on: { "submit-boxset": _vm.getCatalogo },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.boxsets.length > 0
                ? _c("ShopResults", {
                    attrs: {
                      boxsets: _vm.boxsets,
                      focus: _vm.focus,
                      nights: _vm.nights,
                      budget: _vm.budget,
                      title:
                        _vm.boxsetCount +
                        " voucher Salabam che corrispondono alla tua ricerca",
                    },
                    on: { "get-catalogo": _vm.getCatalogo },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }