var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchBoxsetAlert" }, [
    _c("div", { staticClass: "top" }, [
      _vm._v("\n        Desideri prenotare una di queste strutture?\n    "),
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("p", [_vm._v("Acquista il voucher")]),
        _c("BoxsetResume", {
          attrs: {
            focus: _vm.focus,
            band: _vm.band,
            nights: _vm.nights,
            bookingHidePrice: _vm.$config.guiSettings.bookingHidePrice,
          },
        }),
        _vm.mixinGetIntegrationInfo().mode !== "booking"
          ? _c(
              "button",
              {
                staticClass: "btn btn-block btn-secondary",
                on: { click: _vm.boxsetSelected },
              },
              [_vm._v("Scegli")]
            )
          : _vm._e(),
        _c("TornaIndietro"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }