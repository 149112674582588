<template>
    <div id="results" data-e2e="searchResults">
        <div class="pageTop" v-if="config.boxsetsConfig[focus][activeBand] !== undefined">
            <div>
                <img class="boxsetThumbnail" v-bind:src="config.boxsets_images_basepath + config.boxsetsConfig[focus][activeBand][searchedNights].image" />
            </div>
            <div class="mobileResume">
                <BoxsetResume
                    :focus="focus"
                    :band="activeBand"
                    :nights="searchedNights"
                    :price="this.$config.boxsetsConfig[focus][activeBand][searchedNights].public_price"
                    :bookingHidePrice="(this.$config.guiSettings.bookingHidePrice)"
                />
                <div v-if="mixinGetIntegrationInfo().mode !== 'booking'">
                    <!-- sito pubblico -->
                    <a v-if="mixinGetIntegrationInfo().sso == false" href="#" class="btn btn-sm btn-block btn-secondary" @click="$emit('boxset-selected', config.boxsetsConfig[focus][activeBand][searchedNights])">Scegli</a>
                    <!-- sso -->
                    <div v-if="mixinGetIntegrationInfo().sso == true">
                        <button size="sm" class="btn btn-sm btn-block btn-secondary" :disabled="mixinGetIntegrationInfo().integration.availability >= 0 && mixinGetIntegrationInfo().integration.availability < config.boxsetsConfig[focus][activeBand][searchedNights].public_price" @click="$emit('boxset-selected', config.boxsetsConfig[focus][activeBand][searchedNights])">Scegli</button>
                        <span class="creditKoCopy visible" v-if="mixinGetIntegrationInfo().integration.availability >= 0 && mixinGetIntegrationInfo().integration.availability < config.boxsetsConfig[focus][activeBand][searchedNights].public_price">
                        <SvgInfoCircle v-b-popover.hover.top="'Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam'" />
                        credito non sufficiente
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <b-row class="results-info">
             <b-col cols="12" lg="9">
                <p @click="mixinGoBack()">
                    <BtnBack :text="'Torna indietro'" />
                </p>
             </b-col>
           
            <b-col cols="12" lg="9">
                <div :class="'boxset-' + activeBand">
                    <strong>
                        {{geoLabel}}: {{total}} <span v-if="total==1">struttura trovata</span>
                        <span v-else>strutture trovate</span>
                        <b-button v-if="canLoadMore" size="sm" variant="secondary" @click="loadMore()" class="load-more">Aggiungi altre proposte</b-button>
                    </strong>
                    <br>
                    <small v-if="total > 0">
                        Le strutture trovate si trovano nel 
                      <strong><span class="boxset-label">Salabam {{activeBand | capitalize}} {{focus | capitalize}}</span></strong>.<span v-if="mixinGetIntegrationInfo().mode != 'booking'"> Controlla anche gli altri Salabam</span>
                    </small>
                    <small v-else>
                      <small>Non ci sono strutture disponibili nel Salabam Basic Italia.<span v-if="mixinGetIntegrationInfo().mode != 'booking'"> Controlla anche gli altri Salabam</span></small>
                    </small>
                    <div class="d-block">
                        <ActiveFilters />
                    </div>
                </div>
            </b-col>
            <b-col cols="3">
                <!-- map -->
                    <router-link class="nounderline" :to="{ 
                        name: 'map-list',
                        params: { slug: (geoLabel).replace(/ /g, '-'), title: geoLabel, propertyId: 0}
                    }">
                        <div class="map-link">
                            <img src="@/assets/images/map-small.jpg" />
                            <span>Vedi mappa</span>
                        </div>
                    </router-link>
                <!-- map -->
            </b-col>
        </b-row>
        <b-row data-e2e="searchResultsPads">
            <b-col
                v-for="(pad,index) in propertiesWithAlert"
                v-bind:key="index"
                cols="12" sm="6" xl="4" xxl="3"
                :class="'resultsColumn '+ (pad.isProperty ? '' : 'd-sm-none')"
                :style="'order:'+(index+1)+';'"
            >
                <PropertyPad v-if="pad.isProperty" :property="pad" :index="index" />
                <div v-else class="property__box-grid">
                    <div class="property__inner">
                        <SearchBoxsetAlert /><!-- solo smartphone -->
                    </div>
                </div>
            </b-col>

            <b-col
                v-if="searchedMatchLevel != 'nearby' &&
                (
                    (
                        searchedDefaultResultsLength > -1 // ci sono risultati prima chiamata
                     && ( searchedDefaultResultsLength <= (properties.length - searchedDefaultResultsLength) ) // questo serve per gestire nessun risultato nella seconda chiamata, cosi non mette la pad in coda visto che ci sono risultati solo alla prima chiamata
                    )

                    ||

                    // oppure
                    // nessun risultato prima chiamata, risultati nella seconda (pad al primo posto)
                    (
                        searchedDefaultResultsLength == -1
                     && properties.length >= 1
                    )
                )"

                cols="12" sm="6" xl="4" xxl="3"
                :style="'order:'+( (searchedDefaultResultsLength>-1)?searchedDefaultResultsLength:0)+';'"
            >
                <!-- searchedDefaultResultsLength > -1 indica se ci sono oppure no risultati nella prima chiamata -->
                <SearchSuggestion v-if="searchedDefaultResultsLength > -1" :label="'Magari ti interessa scoprire cosa è disponibile anche nei dintorni di <strong>' + geoLabel + '</strong>...'"/>
                <SearchSuggestion v-else :label="'Purtroppo non ci sono disponibilità a <strong>' + geoLabel + '</strong>.<br/><br/> Prova a cambiare ricerca oppure <strong>scopri cosa c\'è nei dintorni</strong>'"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vue from 'vue'
import BtnBack from '../atoms/btn-back'
import PropertyPad from '../blocks/property/property-pad-grid'
import ActiveFilters from '../blocks/active-filters'
import BoxsetResume from '../blocks/boxset/boxset-resume'
import SvgInfoCircle from '@/components/svg/info-circle'
import SearchSuggestion from '@/components/blocks/search-suggestion'
import SearchBoxsetAlert from '@/components/blocks/search-boxset-alert'
import {mapGetters} from "vuex";

export default {
    props: {
        properties: Array,
        geoLabel: String,
        activeBand: String,
        total: Number,
        defaultResultsLength: Number,
        focus: String,
        dates: Object,
        canLoadMore: Boolean,
    },
    components:
    {
        PropertyPad,
        BtnBack,
        ActiveFilters,
        BoxsetResume,
        SvgInfoCircle,
        SearchSuggestion,
        SearchBoxsetAlert,
    },
    data()
    {
        return {
            config: Vue.prototype.$config,
            propertiesWithAlert: [],
            step: 4,//mobile alert
            searchedNights: this.mixinMakeNonReactiveCopy(this.dates.nights),
            searchedMatchLevel: this.mixinMakeNonReactiveCopy(this.matchLevel),
            searchedDefaultResultsLength: this.mixinMakeNonReactiveCopy(this.defaultResultsLength),
        }
    },
    mounted(){
        let _this = this
        setTimeout(function(){
            _this.$nextTick(() => {
                window.scrollTo(0, _this.scroll)
            })
        },50)
        this.parseProperties()
    },
    watch: {
        properties: function () {
            this.parseProperties()
        },
        total: function () {
            this.parseProperties()
        }
    },    
    computed: mapGetters({
      matchLevel: 'getMatchLevel',
      scroll: 'getScroll',
    }),
    methods:
    {
        loadMore()
        {
            this.$emit('load-more')
        },
        parseProperties()
        {
            for (let index = 0; index < this.properties.length; index++) {
                let property = this.properties[index]
                property.isProperty = true            
                this.propertiesWithAlert.push(this.properties[index])

                if (this.mixinGetIntegrationInfo().mode == 'boxset')
                {
                    if((index+1)%this.step == 0)
                    {
                        (this.propertiesWithAlert).push({isProperty: false})
                    }
                }
            }
        }        
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    #results
    {
        //padding-left:$grid-gutter-width/2;
        //padding-right:$grid-gutter-width/2;
        //margin-top:20px;
        
        div[class|="boxset"] {
            & > strong span {
                margin-right: 1rem;
            }
        }
        
        .load-more
        {
            //color:$light-blue;
            //text-decoration: underline;
            //font-weight: normal;
            //cursor: pointer;
            margin:0.5rem 0;
        }
        
        .searchSuggestion
        {
            margin:0;
            padding-bottom:$grid-gutter-width/2;
            height:100%;
            .searchSuggestionInner
            {
                box-shadow: $box-shadow;
                border-bottom-left-radius:$border-radius-large;
                border-bottom-right-radius:$border-radius-large;
                display:flex;
                flex-direction: column;
                overflow: hidden;
            }
            .left
            {
                height:200px;
                min-height:200px;
            }
            .right
            {
                flex:1;
            }
            .left,.right
            {
                max-width: 100%;
            }
            p
            {
                //font-size:1rem;
                strong
                {
                    white-space: initial;
                }
            }
        }
        .results-info
        {
            font-size:24px;
            //padding:$grid-gutter-width/2 $grid-gutter-width;
            display: flex;
            justify-content: space-between;
            //margin-top:$grid-gutter-width/2;
            margin-bottom:$grid-gutter-width/3;
            @include media-breakpoint-down(sm)
            {
                strong
                {
                    font-size: 18px;
                }
            }
            @include media-breakpoint-down(md)
            {
                flex-direction: column;
                line-height: 100%;
                &>div:first-child
                {
                    order:2;
                }
                &>div:last-child
                {
                    order:1;
                }
                small
                {
                    display:block;
                }
                small, small *
                {
                    font-size: 14px;
                    margin-top:0.5rem;
                }
                a
                {
                    display:none;
                }
            }
            @include media-breakpoint-down(lg)
            {
                p
                {
                    font-size: 14px;
                    line-height: 100%;
                    padding-right:1rem;
                    margin-top: 20px;
                }
            }
        }
        /*
        .resultsColumn
        {
            @include media-breakpoint-up(sm)
            {
                
            }
            @include media-breakpoint-up(md)
            {
                
            }
            @include media-breakpoint-up(lg)
            {
                padding-left:$grid-gutter-width/2;
                padding-right:$grid-gutter-width/2;
            }
        }
        */

        .pageTop
        {
            display:flex;
            justify-content:center;
            margin-bottom:1.5rem;
            margin-top:0.5rem;
            .boxsetThumbnail
            {
                width:140px;
                /*
                height:80px;
                min-width:80px;
                min-height:80px;
                */
            }
            .mobileResume
            {
                padding-left:1rem;
                text-align:center;
            }
            .btn
            {
                font-size:11px;
                //display:inline;
                margin:auto;
                max-width:80%;
            }
            .boxsetResume
            {
                //padding-left:1rem;
                margin-bottom:0;
                svg
                {
                    /*
                    width:16px;
                    height:16px;
                    transform:none;
                    */
                    display:none;
                }
                .boxsetName
                {
                    font-size: 21px;
                }
                .salaBadge
                {
                    font-size:11px;
                    padding:3px 8px;
                }
                strong
                {
                    font-size:11px;
                }
                &>* {
                    margin-right: 4px;
                }
                justify-content:center;        
                margin-bottom:1rem;
            }
            .creditKoCopy
            {
                display:block;
                min-width:100%;
                margin-top:0.5rem;
                font-size:11px;
                margin-bottom:0;
                svg
                {
                    width:14px;
                }
            }
            @include media-breakpoint-up(lg)
            {
                display:none;
            }
        }     
    }



    // col-sm-6 col-xl-4 col-xxl-3
    //.resultsColumn
    //{
    @include media-breakpoint-up(sm)
    {
        .resultsColumn
        {
            .property__inner
            {
                margin-bottom:$grid-gutter-width/2 + 10;
            }
        }              
    }
    //}
</style>