var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "45",
        height: "49",
        viewBox: "0 0 45 49",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M42.2899 22.752H38.0409C37.6059 21.2002 36.6781 19.832 35.3973 18.8538C34.1166 17.8755 32.5525 17.3405 30.9409 17.3291C29.3275 17.3585 27.7651 17.8994 26.479 18.874C25.1929 19.8487 24.2495 21.2066 23.7849 22.752H3.04297C2.7675 22.7459 2.49357 22.795 2.2373 22.8962C1.98104 22.9975 1.74762 23.1489 1.55066 23.3416C1.3537 23.5342 1.19717 23.7643 1.09033 24.0183C0.983493 24.2723 0.928467 24.545 0.928467 24.8206C0.928467 25.0961 0.983493 25.3688 1.09033 25.6228C1.19717 25.8768 1.3537 26.1069 1.55066 26.2996C1.74762 26.4922 1.98104 26.6436 2.2373 26.7449C2.49357 26.8461 2.7675 26.8952 3.04297 26.8892H23.7849C24.2095 28.4469 25.1346 29.8216 26.4176 30.8018C27.7006 31.7819 29.2703 32.313 30.8849 32.313C32.4994 32.313 34.0693 31.7819 35.3523 30.8018C36.6353 29.8216 37.5604 28.4469 37.985 26.8892H42.235C42.5067 26.8898 42.7759 26.8366 43.0271 26.7329C43.2783 26.6292 43.5066 26.4771 43.6987 26.2849C43.8909 26.0927 44.0432 25.8645 44.1469 25.6133C44.2505 25.3621 44.3036 25.0928 44.303 24.821C44.3147 24.5509 44.2711 24.2812 44.1748 24.0286C44.0785 23.7759 43.9317 23.5458 43.7432 23.3521C43.5546 23.1583 43.3284 23.005 43.0785 22.9019C42.8286 22.7987 42.5602 22.7476 42.2899 22.752ZM30.9409 28.175C30.0521 28.1727 29.2004 27.8184 28.5719 27.1899C27.9434 26.5615 27.5893 25.7099 27.5869 24.821C27.5869 23.9314 27.9404 23.0781 28.5695 22.449C29.1985 21.8199 30.0517 21.4666 30.9414 21.4666C31.8311 21.4666 32.6844 21.8199 33.3135 22.449C33.9426 23.0781 34.2959 23.9314 34.2959 24.821C34.2933 25.7099 33.9389 26.5617 33.3103 27.1902C32.6817 27.8186 31.8298 28.1727 30.9409 28.175Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.04298 10.5083H7.459C7.88424 12.0654 8.80948 13.4396 10.0924 14.4192C11.3754 15.3987 12.9448 15.9292 14.559 15.9292C16.1731 15.9292 17.7425 15.3987 19.0254 14.4192C20.3083 13.4396 21.2337 12.0654 21.659 10.5083H42.289C42.5608 10.5091 42.8301 10.4562 43.0814 10.3525C43.3328 10.2489 43.561 10.0965 43.7533 9.9043C43.9456 9.71212 44.098 9.48393 44.2018 9.23267C44.3056 8.9814 44.3586 8.71203 44.3579 8.44019C44.3634 8.16699 44.3136 7.89557 44.2116 7.64209C44.1095 7.38861 43.9573 7.15831 43.7641 6.96509C43.5708 6.77187 43.3407 6.61963 43.0872 6.51758C42.8337 6.41553 42.5622 6.3659 42.289 6.37134H21.659C21.2337 4.81421 20.3083 3.44001 19.0254 2.46045C17.7425 1.48089 16.1731 0.950195 14.559 0.950195C12.9448 0.950195 11.3754 1.48089 10.0924 2.46045C8.80948 3.44001 7.88424 4.81421 7.459 6.37134H3.04298C2.77106 6.37055 2.50164 6.4234 2.25026 6.5271C1.99888 6.6308 1.77042 6.7833 1.57814 6.97559C1.38586 7.16787 1.23347 7.39608 1.12978 7.64746C1.02608 7.89884 0.973101 8.16826 0.973893 8.44019C0.968588 8.7133 1.01851 8.98467 1.12062 9.23804C1.22273 9.4914 1.37505 9.72168 1.56825 9.91479C1.76146 10.1079 1.9916 10.2601 2.24501 10.3621C2.49843 10.464 2.76987 10.5137 3.04298 10.5083ZM14.56 5.08521C15.4489 5.08784 16.3005 5.44216 16.929 6.0708C17.5574 6.69944 17.9116 7.55129 17.914 8.44019C17.9221 8.88288 17.8409 9.32279 17.6752 9.7334C17.5095 10.144 17.2628 10.517 16.9497 10.8301C16.6366 11.1432 16.2636 11.39 15.853 11.5557C15.4424 11.7213 15.0026 11.8023 14.56 11.7942C13.6711 11.7918 12.8192 11.4378 12.1906 10.8093C11.5619 10.1809 11.2076 9.32908 11.205 8.44019C11.2265 7.55717 11.5869 6.71637 12.2114 6.0918C12.836 5.46723 13.6769 5.10677 14.56 5.08521Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.2899 39.1331H21.6599C21.2347 37.5759 20.3093 36.2017 19.0264 35.2222C17.7434 34.2426 16.1741 33.7119 14.56 33.7119C12.9458 33.7119 11.3765 34.2426 10.0935 35.2222C8.81058 36.2017 7.88522 37.5759 7.45998 39.1331H3.04396C2.77207 39.1324 2.50268 39.1856 2.25136 39.2893C2.00004 39.3931 1.77174 39.5453 1.57948 39.7375C1.38723 39.9298 1.23486 40.1581 1.13112 40.4094C1.02738 40.6607 0.974333 40.9303 0.974992 41.2021C0.969692 41.4753 1.0195 41.7466 1.1216 42C1.2237 42.2534 1.37592 42.4836 1.56911 42.6768C1.7623 42.8699 1.99257 43.0222 2.24599 43.1243C2.49941 43.2264 2.7708 43.2763 3.04396 43.271H7.45998C7.88522 44.8281 8.81058 46.2023 10.0935 47.1819C11.3765 48.1614 12.9458 48.6921 14.56 48.6921C16.1741 48.6921 17.7434 48.1614 19.0264 47.1819C20.3093 46.2023 21.2347 44.8281 21.6599 43.271H42.2899C42.5618 43.2717 42.8312 43.2187 43.0825 43.115C43.3339 43.0112 43.5622 42.8588 43.7544 42.6665C43.9467 42.4742 44.099 42.2459 44.2028 41.9946C44.3065 41.7433 44.3596 41.474 44.3589 41.2021C44.3642 40.929 44.3144 40.6575 44.2123 40.4041C44.1102 40.1506 43.958 39.9205 43.7648 39.7273C43.5716 39.5341 43.3413 39.3819 43.0879 39.2798C42.8345 39.1777 42.5631 39.1278 42.2899 39.1331ZM14.56 44.5562C13.6711 44.5538 12.8192 44.1995 12.1906 43.571C11.5619 42.9426 11.2076 42.091 11.205 41.2021C11.205 40.3125 11.5584 39.4592 12.1875 38.8301C12.8166 38.201 13.6698 37.8477 14.5595 37.8477C15.4491 37.8477 16.3023 38.201 16.9314 38.8301C17.5605 39.4592 17.914 40.3125 17.914 41.2021C17.9116 42.091 17.5575 42.9426 16.929 43.571C16.3005 44.1995 15.4488 44.5538 14.56 44.5562Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }