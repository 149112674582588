<template>
    <div class="noResultsHelp">
        <div class="noResultsHelpInner panel-gray">
            <SvgLightHelpOrange class="iconHelp" />
            <div class="help">
                <div class="top">
                    <SvgLens />
                    <h5 class="title">Cambia la ricerca</h5>
                </div>
                <span>Prova a cercare in altre destinazioni o altre date</span>
            </div>
            <div class="help">
                <div class="top">
                    <SvgFilters />
                    <h5 class="title">Riduci i filtri</h5>
                </div>
                <span>Controlla di non avere inserito troppi filtri</span>
            </div>
            <div class="help">
                <div class="top">
                    <SvgBoxsetIcon />
                    <h5 class="title">Scopri gli altri voucher</h5>
                </div>
                <span>Controlla se ci sono altre disponibilità su voucher diversi</span>
            </div>
            <div class="help">
                <div class="top">
                    <SvgMail />
                    <h5 class="title">Contatta l'assistenza</h5>
                </div>
                <span><a href="mailto:assistenza@salabam.com">assistenza@salabam.com</a></span>
            </div>
        </div>
    </div>
</template>

<script>
import SvgLightHelpOrange from '../svg/light-help-orange'
import SvgLens from '../svg/lens'
import SvgFilters from '../svg/toggle-search'
import SvgBoxsetIcon from '../svg/boxset-icon'
import SvgMail from '../svg/mail'
export default {
    name: 'no-results-help',
    props:{
    },
    components:
    {
        SvgLightHelpOrange,
        SvgLens,
        SvgFilters,
        SvgBoxsetIcon,
        SvgMail,
    },
    data() {
        return {
        }
    },
    mounted()
    {},
    methods:
    {}
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    .noResultsHelp
    {
        margin:2rem 4rem;
        .iconHelp
        {
            position:absolute;
            top:0;
            right:0;
            transform:translateY(-30%) translateX(30%);
            max-width: 100px;
        }
        .iconSmall
        {
            width:14px;
            height:auto;
            *
            {
                fill:$black;
            }
        }
        .help
        {
            display:flex;
            width:50%;
            padding:0 0.25rem;
            flex-direction: column;
            align-items: center;
            text-align:center;
            .top
            {
                
            }

            .title
            {
                color:$black;
                font-weight: normal;
                font-size: 1rem;
                min-height: 2.5rem;
            }

            @include media-breakpoint-up(lg)
            {
                width:25%;
            }
            span
            {
                display:inline-block;
                padding-left: 0.35rem;
                font-size: 14px;
                line-height: 130%;
                padding-right:1rem;
                font-size: 0.75rem;
                a
                {
                    color:$black;
                }
            }
            svg
            {
                width:32px;
                height:32px;
                max-width: 90%;
                margin-bottom:1rem;
                * {
                    fill:$black;
                }
            }
        }
        .noResultsHelpInner
        {
            position:relative;
            max-width:800px;
            margin:0 auto;
            //border:1px solid $medium-gray;
            //background-color:$gray-200;
            padding:$grid-gutter-width $grid-gutter-width/2;
            display:flex;
            flex-wrap:wrap;
            justify-content: center;;
        }
    }
</style>