var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.config).length !== 0
    ? _c("div", { key: _vm.refreshKey, attrs: { id: "search" } }, [
        _c(
          "div",
          {
            class:
              Object.keys(_vm.boxsets).length > 0 ? "hasResults" : "noResults",
            attrs: { id: "main" },
          },
          [
            _c("Sidebar", {
              attrs: {
                loading: !!(_vm.loading | _vm.appending),
                boxsets: _vm.boxsets,
                bands: _vm.bands,
                resultsFiltered: _vm.resultsFiltered,
                focus: _vm.focus,
                hasResults: Object.keys(_vm.boxsets).length !== 0,
                sidebarNights: _vm.sidebarNights,
              },
              on: {
                "nightly-price-changed": _vm.nightlyPriceChanged,
                "boxset-selected": _vm.boxsetSelected,
                "submit-search": _vm.submitSearch,
              },
            }),
            _c(
              "div",
              { class: "boxset-" + _vm.activeBand, attrs: { id: "content" } },
              [
                _c("SearchBar", {
                  attrs: {
                    loading: !!(_vm.loading | _vm.appending),
                    band: _vm.activeBand,
                    focus: _vm.focus,
                    geo: _vm.geo,
                    dates: _vm.dates,
                    occupancy: _vm.occupancy,
                    hasResults: Object.keys(_vm.boxsets).length !== 0,
                    offset: _vm.offset,
                    lastSearch: _vm.lastSearch,
                  },
                  on: {
                    "submit-search": _vm.submitSearch,
                    "filter-changed": _vm.filterChanged,
                  },
                }),
                _c(
                  "div",
                  {
                    class: _vm.loading || _vm.appending ? "pageLoading" : "",
                    attrs: { id: "page" },
                  },
                  [
                    _vm.loading
                      ? _c("Spinner", { staticClass: "text-center" })
                      : (this.$route.name == "property") |
                        (this.$route.name == "map-detail") |
                        (this.$route.name == "map-list") |
                        (this.$route.name == "before-checkout")
                      ? _c("div", [_c("router-view")], 1)
                      : Object.keys(_vm.boxsets).length == 0
                      ? _c("p", { staticClass: "searchEmpty" }, [
                          _vm._v("\n          Inizia a cercare!\n        "),
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "noheader",
                                attrs: { "no-body": "" },
                              },
                              [
                                _c(
                                  "b-tabs",
                                  {
                                    key: _vm.resultsFiltered[this.activeBand]
                                      .length,
                                    attrs: { card: "", "no-fade": "" },
                                    model: {
                                      value: _vm.activeBandIndex,
                                      callback: function ($$v) {
                                        _vm.activeBandIndex = $$v
                                      },
                                      expression: "activeBandIndex",
                                    },
                                  },
                                  _vm._l(
                                    _vm.bands,
                                    function (value, name, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: "propertys-" + index,
                                          attrs: {
                                            resultsFiltered:
                                              _vm.resultsFiltered,
                                          },
                                        },
                                        [
                                          _vm.resultsFiltered[value].length > 0
                                            ? _c(
                                                "b-tab",
                                                [
                                                  _c(
                                                    "b-card-text",
                                                    [
                                                      _c("Results", {
                                                        attrs: {
                                                          dates: _vm.dates,
                                                          geoLabel:
                                                            _vm.geo.label,
                                                          total:
                                                            _vm.resultsFiltered[
                                                              value
                                                            ].length,
                                                          defaultResultsLength:
                                                            _vm.boxsets[
                                                              value
                                                            ] !== undefined &&
                                                            _vm.boxsets[value][
                                                              _vm.dates.nights
                                                            ] !== undefined
                                                              ? _vm.boxsets[
                                                                  value
                                                                ][
                                                                  _vm.dates
                                                                    .nights
                                                                ]
                                                                  .defaultResultsLength
                                                              : 999999,
                                                          activeBand:
                                                            _vm.activeBand,
                                                          focus: _vm.focus,
                                                          properties:
                                                            _vm.resultsFiltered[
                                                              value
                                                            ],
                                                          canLoadMore:
                                                            _vm.offset !== -1 &&
                                                            !_vm.loading &&
                                                            !_vm.appending,
                                                        },
                                                        on: {
                                                          "reset-results":
                                                            _vm.resetResultsAndTrigger,
                                                          "submit-search":
                                                            _vm.submitSearch,
                                                          "filter-changed":
                                                            _vm.filterChanged,
                                                          "boxset-selected":
                                                            _vm.boxsetSelected,
                                                          "load-more":
                                                            _vm.getResults,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.offset !== -1 &&
                                                  _vm.resultsFiltered[
                                                    _vm.activeBand
                                                  ].length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cta__load-more text-center",
                                                        },
                                                        [
                                                          !_vm.appending
                                                            ? _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "secondary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.getResults,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      Aggiungi altre proposte\n                    "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-spinner",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "primary",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "b-tab",
                                                {
                                                  attrs: {
                                                    "title-item-class": "",
                                                  },
                                                },
                                                [
                                                  _c("NoResults", {
                                                    attrs: {
                                                      destinationInfo: {
                                                        label: _vm.geo.label,
                                                        focus: _vm.focus,
                                                        band: _vm.activeBand,
                                                        dates: _vm.dates,
                                                        occupancy:
                                                          _vm.occupancy,
                                                      },
                                                    },
                                                    on: {
                                                      "submit-search":
                                                        _vm.getResults,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _c(
                  "b-container",
                  { attrs: { fluid: "" } },
                  [
                    _vm.$route.name == "search"
                      ? _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { sm: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("br"),
                                  _c("BtnBack", {
                                    attrs: { text: "Torna indietro" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("PageFooter"),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }