var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "results", "data-e2e": "searchResults" } },
    [
      _vm.config.boxsetsConfig[_vm.focus][_vm.activeBand] !== undefined
        ? _c("div", { staticClass: "pageTop" }, [
            _c("div", [
              _c("img", {
                staticClass: "boxsetThumbnail",
                attrs: {
                  src:
                    _vm.config.boxsets_images_basepath +
                    _vm.config.boxsetsConfig[_vm.focus][_vm.activeBand][
                      _vm.searchedNights
                    ].image,
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mobileResume" },
              [
                _c("BoxsetResume", {
                  attrs: {
                    focus: _vm.focus,
                    band: _vm.activeBand,
                    nights: _vm.searchedNights,
                    price:
                      this.$config.boxsetsConfig[_vm.focus][_vm.activeBand][
                        _vm.searchedNights
                      ].public_price,
                    bookingHidePrice: this.$config.guiSettings.bookingHidePrice,
                  },
                }),
                _vm.mixinGetIntegrationInfo().mode !== "booking"
                  ? _c("div", [
                      _vm.mixinGetIntegrationInfo().sso == false
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-sm btn-block btn-secondary",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "boxset-selected",
                                    _vm.config.boxsetsConfig[_vm.focus][
                                      _vm.activeBand
                                    ][_vm.searchedNights]
                                  )
                                },
                              },
                            },
                            [_vm._v("Scegli")]
                          )
                        : _vm._e(),
                      _vm.mixinGetIntegrationInfo().sso == true
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-block btn-secondary",
                                attrs: {
                                  size: "sm",
                                  disabled:
                                    _vm.mixinGetIntegrationInfo().integration
                                      .availability >= 0 &&
                                    _vm.mixinGetIntegrationInfo().integration
                                      .availability <
                                      _vm.config.boxsetsConfig[_vm.focus][
                                        _vm.activeBand
                                      ][_vm.searchedNights].public_price,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "boxset-selected",
                                      _vm.config.boxsetsConfig[_vm.focus][
                                        _vm.activeBand
                                      ][_vm.searchedNights]
                                    )
                                  },
                                },
                              },
                              [_vm._v("Scegli")]
                            ),
                            _vm.mixinGetIntegrationInfo().integration
                              .availability >= 0 &&
                            _vm.mixinGetIntegrationInfo().integration
                              .availability <
                              _vm.config.boxsetsConfig[_vm.focus][
                                _vm.activeBand
                              ][_vm.searchedNights].public_price
                              ? _c(
                                  "span",
                                  { staticClass: "creditKoCopy visible" },
                                  [
                                    _c("SvgInfoCircle", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value:
                                            "Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam",
                                          expression:
                                            "'Ci spiace! Il tuo attuale credito non è sufficiente ad acquistare questo voucher Salabam'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                    }),
                                    _vm._v(
                                      "\n                    credito non sufficiente\n                    "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "results-info" },
        [
          _c("b-col", { attrs: { cols: "12", lg: "9" } }, [
            _c(
              "p",
              {
                on: {
                  click: function ($event) {
                    return _vm.mixinGoBack()
                  },
                },
              },
              [_c("BtnBack", { attrs: { text: "Torna indietro" } })],
              1
            ),
          ]),
          _c("b-col", { attrs: { cols: "12", lg: "9" } }, [
            _c("div", { class: "boxset-" + _vm.activeBand }, [
              _c(
                "strong",
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.geoLabel) +
                      ": " +
                      _vm._s(_vm.total) +
                      " "
                  ),
                  _vm.total == 1
                    ? _c("span", [_vm._v("struttura trovata")])
                    : _c("span", [_vm._v("strutture trovate")]),
                  _vm.canLoadMore
                    ? _c(
                        "b-button",
                        {
                          staticClass: "load-more",
                          attrs: { size: "sm", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.loadMore()
                            },
                          },
                        },
                        [_vm._v("Aggiungi altre proposte")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("br"),
              _vm.total > 0
                ? _c("small", [
                    _vm._v(
                      "\n                    Le strutture trovate si trovano nel \n                  "
                    ),
                    _c("strong", [
                      _c("span", { staticClass: "boxset-label" }, [
                        _vm._v(
                          "Salabam " +
                            _vm._s(_vm._f("capitalize")(_vm.activeBand)) +
                            " " +
                            _vm._s(_vm._f("capitalize")(_vm.focus))
                        ),
                      ]),
                    ]),
                    _vm._v("."),
                    _vm.mixinGetIntegrationInfo().mode != "booking"
                      ? _c("span", [
                          _vm._v(" Controlla anche gli altri Salabam"),
                        ])
                      : _vm._e(),
                  ])
                : _c("small", [
                    _c("small", [
                      _vm._v(
                        "Non ci sono strutture disponibili nel Salabam Basic Italia."
                      ),
                      _vm.mixinGetIntegrationInfo().mode != "booking"
                        ? _c("span", [
                            _vm._v(" Controlla anche gli altri Salabam"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
              _c("div", { staticClass: "d-block" }, [_c("ActiveFilters")], 1),
            ]),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "nounderline",
                  attrs: {
                    to: {
                      name: "map-list",
                      params: {
                        slug: _vm.geoLabel.replace(/ /g, "-"),
                        title: _vm.geoLabel,
                        propertyId: 0,
                      },
                    },
                  },
                },
                [
                  _c("div", { staticClass: "map-link" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/images/map-small.jpg") },
                    }),
                    _c("span", [_vm._v("Vedi mappa")]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { attrs: { "data-e2e": "searchResultsPads" } },
        [
          _vm._l(_vm.propertiesWithAlert, function (pad, index) {
            return _c(
              "b-col",
              {
                key: index,
                class: "resultsColumn " + (pad.isProperty ? "" : "d-sm-none"),
                style: "order:" + (index + 1) + ";",
                attrs: { cols: "12", sm: "6", xl: "4", xxl: "3" },
              },
              [
                pad.isProperty
                  ? _c("PropertyPad", {
                      attrs: { property: pad, index: index },
                    })
                  : _c("div", { staticClass: "property__box-grid" }, [
                      _c(
                        "div",
                        { staticClass: "property__inner" },
                        [_c("SearchBoxsetAlert")],
                        1
                      ),
                    ]),
              ],
              1
            )
          }),
          _vm.searchedMatchLevel != "nearby" &&
          ((_vm.searchedDefaultResultsLength > -1 && // ci sono risultati prima chiamata
            _vm.searchedDefaultResultsLength <=
              _vm.properties.length - _vm.searchedDefaultResultsLength) || // questo serve per gestire nessun risultato nella seconda chiamata, cosi non mette la pad in coda visto che ci sono risultati solo alla prima chiamata
            // oppure
            // nessun risultato prima chiamata, risultati nella seconda (pad al primo posto)
            (_vm.searchedDefaultResultsLength == -1 &&
              _vm.properties.length >= 1))
            ? _c(
                "b-col",
                {
                  style:
                    "order:" +
                    (_vm.searchedDefaultResultsLength > -1
                      ? _vm.searchedDefaultResultsLength
                      : 0) +
                    ";",
                  attrs: { cols: "12", sm: "6", xl: "4", xxl: "3" },
                },
                [
                  _vm.searchedDefaultResultsLength > -1
                    ? _c("SearchSuggestion", {
                        attrs: {
                          label:
                            "Magari ti interessa scoprire cosa è disponibile anche nei dintorni di <strong>" +
                            _vm.geoLabel +
                            "</strong>...",
                        },
                      })
                    : _c("SearchSuggestion", {
                        attrs: {
                          label:
                            "Purtroppo non ci sono disponibilità a <strong>" +
                            _vm.geoLabel +
                            "</strong>.<br/><br/> Prova a cambiare ricerca oppure <strong>scopri cosa c'è nei dintorni</strong>",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }