var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "noResultsHelp" }, [
    _c(
      "div",
      { staticClass: "noResultsHelpInner panel-gray" },
      [
        _c("SvgLightHelpOrange", { staticClass: "iconHelp" }),
        _c("div", { staticClass: "help" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SvgLens"),
              _c("h5", { staticClass: "title" }, [_vm._v("Cambia la ricerca")]),
            ],
            1
          ),
          _c("span", [
            _vm._v("Prova a cercare in altre destinazioni o altre date"),
          ]),
        ]),
        _c("div", { staticClass: "help" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SvgFilters"),
              _c("h5", { staticClass: "title" }, [_vm._v("Riduci i filtri")]),
            ],
            1
          ),
          _c("span", [_vm._v("Controlla di non avere inserito troppi filtri")]),
        ]),
        _c("div", { staticClass: "help" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SvgBoxsetIcon"),
              _c("h5", { staticClass: "title" }, [
                _vm._v("Scopri gli altri voucher"),
              ]),
            ],
            1
          ),
          _c("span", [
            _vm._v(
              "Controlla se ci sono altre disponibilità su voucher diversi"
            ),
          ]),
        ]),
        _c("div", { staticClass: "help" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SvgMail"),
              _c("h5", { staticClass: "title" }, [
                _vm._v("Contatta l'assistenza"),
              ]),
            ],
            1
          ),
          _vm._m(0),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { attrs: { href: "mailto:assistenza@salabam.com" } }, [
        _vm._v("assistenza@salabam.com"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }