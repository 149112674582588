<template>
    <div class="noResults">
        <div class="noResultsTop">
            <!--<div class="noResultsIcon"><SvgNoResults /></div>-->
            <div class="noResultsText">
                <strong>
                    <span v-if="mode == 'noVouchers'">
                        Non ci sono risultati per la tua ricerca
                    </span>
                    <span v-else>
                        <span v-if="mixinGetIntegrationInfo().mode == 'boxset'">Non ci sono disponibilità per questa ricerca e questo voucher</span>
                        <span v-if="mixinGetIntegrationInfo().mode == 'booking'">Il tuo voucher non ha risultati per questa ricerca</span>
                    </span>
                </strong>
                <div v-if="mode == 'noVouchers'" class="noVouchers">
                    <ul>
                        <li>Dove: {{voucherInfo.focus}}</li>
                        <li>Budget: {{voucherInfo.budget}} €</li>
                        <li>
                            <span v-if="voucherInfo.nights !== 1">Notti</span><span v-else>Notte</span>: {{voucherInfo.nights}}</li>
                    </ul>
                </div>
                <div v-if="mode == 'noDestinations'">
                    <div>
                        <br>
                        <p>
                            {{label}} - {{checkin | formatDate}},
                            {{nights}} <span v-if="nights !== 1">notti</span><span v-else>notte</span>,
                            {{adults}} <span v-if="adults !== 1">adulti</span><span v-else>adulto</span>
                            <span v-if="children > 0"> - {{children}} <span v-if="children !== 1">bambini</span><span v-else>bambino</span></span>
                        </p>
                        <p>
                            <BoxsetResume
                                :focus="focus"
                                :band="band"
                                :nights="nights"
                                :bookingHidePrice="$config.guiSettings.bookingHidePrice"
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <br>

        <div class="noResultsBottom">

            <div v-if="mode == 'noVouchers'" class="noVouchers">
                <div v-if="$config.bestSellersBoxsets">
                    <br><br>
                    <BestSellers />
                </div>
            </div>
            <div v-if="mode == 'noDestinations'">
                <NoResultsHelp />
                <div v-if="mixinGetIntegrationInfo().integration == false">
                    <!-- noDestination -> Booking -->
                    <br>
                    <div class="panel panel-gray">
                        <b-row>
                            <b-col sm="12" lg="4" class="text-left d-flex align-items-center">
                                <strong>NON HAI UNA<br>DESTINAZIONE PRECISA?</strong>
                            </b-col>
                            <b-col sm="12" lg="4" class="text-center d-flex align-items-center flex-column">

                                <b-button class="btn-block btn-transparent-secondary" size="sm" @click="mixinGoTo('voucher-perfetto',{})">Scegli il voucher perfetto</b-button>

                                <div class="mt-2">
                                    <small><strong>
                                        <CopyScadenza
                                            default="e prenditi {value} mesi per decidere dove andare"
                                            promoMonths="e prenditi {value} mesi per decidere dove andare"
                                            promoFixed="e hai tempo fino al {value} per decidere dove andare"
                                        />
                                    </strong></small>
                                </div>
                            </b-col>
                            <b-col sm="12" lg="4" class="text-center d-flex align-items-center flex-column" v-if="$config.guiSettings.showInSalabam">
                                <b-button class="btn-block btn-transparent-secondary" size="sm" @click="mixinGoTo('homepage-in-salabam',{})">Lasciati ispirare</b-button>
                                <div class="mt-2">
                                    <small><strong>
                                        <CopyScadenza
                                            default="e prenditi {value} mesi per decidere dove andare"
                                            promoMonths="e prenditi {value} mesi per decidere dove andare"
                                            promoFixed="e hai tempo fino al {value} per decidere dove andare"
                                        />
                                    </strong></small>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div v-else>
                    <!-- noDestination -> Booking -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
//import SearchBoxset from '@/components/blocks/search-form/search-boxset'
import BestSellers from '../blocks/best-sellers'
import BoxsetResume from '../blocks/boxset/boxset-resume'
import NoResultsHelp from '../blocks/no-results-help'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'
//import SvgNoResults from '../svg/no-results'
//import SearchSuggestion from '../blocks/search-suggestion'
import {mapGetters} from "vuex";

export default {
    name: 'no-results',
    props:{
        voucherInfo:
        {
            type: Object,
            default: () => {return {}}
        },
        destinationInfo:
        {
            type: Object,
            default: () => {return {}}
        }
    },
    components:
    {
        //SearchBoxset,
        BestSellers,
        BoxsetResume,
        NoResultsHelp,
        CopyScadenza,
        //SvgNoResults,
        //SearchSuggestion,
    },
    data() {
        // se arrivo dalla ricerca, vedi search.vue
        if((Object.keys(this.destinationInfo)).length !== 0)
        {
            return {
                mode: 'noDestinations',
                label: this.destinationInfo.label,
                checkin: this.destinationInfo.dates.checkin,
                nights: this.destinationInfo.dates.nights,
                adults: this.destinationInfo.occupancy.adults,
                children: this.destinationInfo.occupancy.children.length,
                focus: this.destinationInfo.focus,
                band: this.destinationInfo.band,
            }
        }
        // se arrivo dallo shop, vedi catalog.vue
        else
        {
            return {
                mode: 'noVouchers',
            }
        }
    },
    mounted()
    {},
    methods:
    {},
    computed: mapGetters({
      matchLevel: 'getMatchLevel',
    }),
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    .noResults
    {
        .searchSuggestionInner
        {
            display:flex;
            align-items: center;
            justify-content: center;

        }
        .noResultsTop
        {
            display:flex;
            align-items: center;
            justify-content: center;
            width:100%;
            margin:0 auto;
            max-width: 800px;
            text-align:center;
            margin-top:$grid-gutter-width/2;
            .noResultsIcon
            {
                max-width:20%;
                padding-right:2rem;
                svg
                {
                    width:100%;
                }
            }
            strong
            {
                font-size: 18px;
            }
            ul
            {
                margin-top:1rem;
                li
                {
                    position:relative;
                    padding-left:0.75rem;
                    font-size: 14px;
                    margin-bottom:3px;
                    &:before
                    {
                        content:"";
                        position:absolute;
                        top:50%;
                        transform:translateY(-20%);
                        width:4px;
                        height:4px;
                        border-radius:50%;
                        left:0;
                        background: $black;
                    }
                }
            }
        }

        .noDestinations
        {
            strong
            {
            }
        }
        .flex-column
        {
            a
            {
                min-width: 100%;
            }
        }
        .btn-block
        {
            max-width: 300px;
            margin:auto;
        }
    }
</style>