var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "catalogResults", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12" } }, [
                _c("h1", { staticClass: "common-title catalogTitle" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "boxsets" },
                [
                  _c(
                    "b-row",
                    [
                      _vm._l(_vm.boxsets.slice(0, 6), function (boxset, index) {
                        return _c(
                          "b-col",
                          {
                            key: "step-1" + index,
                            attrs: { sm: "12", md: "4", lg: "3" },
                          },
                          [_c("BoxsetCatalog", { attrs: { boxset: boxset } })],
                          1
                        )
                      }),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "6" } },
                        [_c("b-container", [_c("ComeFunzionaSalabam")], 1)],
                        1
                      ),
                      _vm._l(
                        _vm.boxsets.slice(6, _vm.boxsets.length),
                        function (boxset, index) {
                          return _c(
                            "b-col",
                            {
                              key: "step-2" + index,
                              attrs: { sm: "12", md: "4", lg: "3" },
                            },
                            [
                              _c("BoxsetCatalog", {
                                attrs: { boxset: boxset },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c(
                            "b-container",
                            { staticClass: "mb-4 pb-4" },
                            [
                              _c("br"),
                              _c("br"),
                              _c("TermsShort"),
                              _c("br"),
                              _c("br"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.boxsets.length == 0
        ? _c(
            "b-container",
            { staticClass: "catalogResults", attrs: { fluid: "" } },
            [
              _c("NoResults", {
                attrs: {
                  voucherInfo: {
                    focus: _vm.focus,
                    nights: _vm.nights,
                    budget: _vm.budget,
                  },
                },
                on: {
                  "submit-search": function ($event) {
                    return _vm.$emit("get-catalogo")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }