var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedAmenities.length |
    _vm.selectedCategories.length |
    _vm.selectedRates.length
    ? _c(
        "div",
        { staticClass: "active-filters" },
        [
          _vm._l(_vm.selectedCategories, function (category, index) {
            return _c(
              "b-badge",
              { key: "cat-" + index, attrs: { variant: "secondary" } },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.categories[category]) + "\n        "
                ),
                _c(
                  "span",
                  {
                    staticClass: "remove-filter",
                    on: {
                      click: function ($event) {
                        return _vm.removeCategory(category)
                      },
                    },
                  },
                  [_vm._v("X")]
                ),
              ]
            )
          }),
          _vm._l(_vm.selectedAmenities, function (amenity, index) {
            return _c(
              "b-badge",
              { key: "am-" + index, attrs: { variant: "secondary" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.amenities[amenity].label) +
                    "\n        "
                ),
                _c(
                  "span",
                  {
                    staticClass: "remove-filter",
                    on: {
                      click: function ($event) {
                        return _vm.removeAmenity(amenity)
                      },
                    },
                  },
                  [_vm._v("X")]
                ),
              ]
            )
          }),
          _vm._l(_vm.selectedRates, function (rate, index) {
            return _c(
              "b-badge",
              { key: "rat-" + index, attrs: { variant: "gold" } },
              [
                _vm._v("\n        " + _vm._s(rate) + " stelle\n        "),
                _c(
                  "span",
                  {
                    staticClass: "remove-filter",
                    on: {
                      click: function ($event) {
                        return _vm.removeRate(rate)
                      },
                    },
                  },
                  [_vm._v("X")]
                ),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }