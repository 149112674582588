var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "125",
        height: "125",
        viewBox: "0 0 125 125",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "62.5", cy: "62.5", r: "62.5", fill: "#E7775A" },
      }),
      _c("path", {
        attrs: {
          d: "M83.9979 61.0463C83.9979 49.4588 74.5425 40.0034 62.955 40.0034C51.3675 40.0034 41.9121 49.4588 41.9121 61.0463C41.9121 62.7149 42.0975 64.3835 42.561 66.0521C43.1172 68.7404 44.1369 70.409 45.5274 72.7265C45.8055 73.2827 46.1763 73.8389 46.5471 74.4878C47.0106 75.2294 47.3814 75.971 47.8449 76.6199C49.6989 79.679 50.8113 81.4403 50.8113 85.0556V93.7693C50.8113 95.9941 52.3872 97.7555 54.5193 98.1263C55.4463 102.854 58.2273 105.82 62.955 105.82C67.6827 105.82 70.5564 102.854 71.3907 98.1263C73.5228 97.7555 75.0987 95.9014 75.0987 93.7693V85.0556C75.0987 81.4403 76.2111 79.5863 78.0651 76.6199C78.4359 75.971 78.8994 75.2294 79.3629 74.4878C79.7337 73.8389 80.1045 73.2827 80.3826 72.7265C81.7731 70.409 82.7928 68.7404 83.349 66.0521C83.8125 64.3835 83.9979 62.7149 83.9979 61.0463ZM71.4834 87.2803H54.612V85.1483C54.612 84.6848 54.612 84.3139 54.5193 83.9431H71.4834C71.4834 84.3139 71.3907 84.6848 71.3907 85.1483V87.2803H71.4834ZM70.6491 94.6036H55.2609C54.7974 94.6036 54.5193 94.2329 54.5193 93.8621V90.9883H71.3907V93.8621C71.4834 94.2329 71.1126 94.6036 70.6491 94.6036ZM62.955 102.205C61.9353 102.205 59.3397 102.205 58.32 98.3116H67.6827C66.663 102.205 63.9747 102.205 62.955 102.205ZM79.7337 65.3105C79.2702 67.3499 78.5286 68.7404 77.2308 70.8725C76.86 71.4287 76.5819 71.9849 76.2111 72.6338C75.7476 73.3754 75.3768 74.1169 75.006 74.6731C73.8936 76.5271 72.8739 78.1958 72.225 80.1425H53.8704C53.2215 78.1958 52.2945 76.5271 51.0894 74.6731C50.7186 74.0242 50.2551 73.3754 49.8843 72.6338C49.5135 71.9849 49.1427 71.336 48.8646 70.8725C47.5668 68.6477 46.8252 67.3499 46.3617 65.2178C45.9909 63.8273 45.8055 62.4368 45.8055 61.0463C45.8055 51.4982 53.5923 43.7114 63.1404 43.7114C72.6885 43.7114 80.4753 51.4982 80.4753 61.0463C80.2899 62.4368 80.1045 63.8273 79.7337 65.3105Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M101.146 59.1924H93.4517C92.432 59.1924 91.5977 60.0267 91.5977 61.0464C91.5977 62.0661 92.432 62.9004 93.4517 62.9004H101.146C102.165 62.9004 103 62.0661 103 61.0464C103 60.0267 102.165 59.1924 101.146 59.1924Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M84.4614 41.3941C84.9249 41.3941 85.3884 41.2086 85.7592 40.8378L91.2285 35.3686C91.9701 34.627 91.9701 33.5146 91.2285 32.773C90.4869 32.0314 89.3745 32.0314 88.6329 32.773L83.1636 38.2423C82.422 38.9839 82.422 40.0962 83.1636 40.8378C83.5344 41.2086 83.9979 41.3941 84.4614 41.3941Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M62.9536 32.4021C63.9733 32.4021 64.8076 31.5678 64.8076 30.5481V22.854C64.8076 21.8343 63.9733 21 62.9536 21C61.9339 21 61.0996 21.8343 61.0996 22.854V30.5481C61.0996 31.5678 61.9339 32.4021 62.9536 32.4021Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.1486 40.8378C40.5194 41.2086 40.9828 41.3941 41.4463 41.3941C41.9098 41.3941 42.3733 41.2086 42.7441 40.8378C43.4857 40.0962 43.4857 38.9839 42.7441 38.2423L37.2748 32.773C36.5332 32.0314 35.4208 32.0314 34.6792 32.773C33.9376 33.5146 33.9376 34.627 34.6792 35.3686L40.1486 40.8378Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.5481 59.1924H24.854C23.8343 59.1924 23 60.0267 23 61.0464C23 62.0661 23.8343 62.9004 24.854 62.9004H32.5481C33.5678 62.9004 34.4021 62.0661 34.4021 61.0464C34.4021 60.0267 33.5678 59.1924 32.5481 59.1924Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.1486 81.2549L34.6792 86.7242C33.9376 87.4658 33.9376 88.5782 34.6792 89.3198C35.05 89.6906 35.5136 89.876 35.9771 89.876C36.4406 89.876 36.904 89.6906 37.2748 89.3198L42.7441 83.8505C43.4857 83.1089 43.4857 81.9965 42.7441 81.2549C42.0026 80.5133 40.7975 80.5133 40.1486 81.2549Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M85.7592 81.2549C85.0176 80.5133 83.9052 80.5133 83.1636 81.2549C82.422 81.9965 82.422 83.1089 83.1636 83.8505L88.6329 89.3198C89.0037 89.6906 89.4672 89.876 89.9307 89.876C90.3942 89.876 90.8577 89.6906 91.2285 89.3198C91.9701 88.5782 91.9701 87.4658 91.2285 86.7242L85.7592 81.2549Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }